import { StyleSheet } from 'aphrodite'

import './src/components/layouts/layout.css'

//import "./node_modules/inno-components/build/index.css"
//import "./src/styles/lib/grid.css"

import './static/css/bootstrap.min.css'

import './static/css/styles.css'

/*
import "./static/js/jquery-3.3.1.min.js"
import "./static/js/bootstrap.min.js"
import "./static/js/bootstrap-dropdownhover.min.js"
import "./static/js/lity.min.js"
import "./static/js/owl.carousel.min.js"
import "./static/js/script.js"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
