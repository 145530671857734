module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-aphrodite/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/@wapps/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"primaryColor":"#9c27b0","typography":{"useNextVariants":true}}},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
